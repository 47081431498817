<template>
  <div
    :class="`q-pa-md ${$q.dark.isActive ? '' : 'bg-grey-2'}`"
    style="min-height: calc(100vh - 50px);"
  >
    <div class="row">
      <div
        v-for="option in options"
        :key="option.path"
        class="col-12 col-md-4 col-sm-6 q-pa-md"
      >
        <q-card
          class="clickable bg-white text-dark fit"
          @click="handleRoute(option)"
        >
          <q-card-section class="border-bottom q-px-none q-py-xs">
            <q-legend
              :label="$t(option.name)"
              :icon="option.icon"
            />
          </q-card-section>

          <q-card-section class="text-caption">
            {{ $t(option.description) }}
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationsHub',
  data () {
    return {
      options: this.$utils.router.getRouteByEntity('Orderadmin\\Notifications').children
    }
  },
  methods: {
    handleRoute (option) {
      this.$router.push(option.path)
    }
  }
}
</script>
